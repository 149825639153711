import axios from 'axios';
import store from '@/store';
import router from '@/router';

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL || '/api',
  headers: {
    'Content-Type': 'application/json',
  }
});

export const setupInterceptors = () => {
  // Request interceptor
  api.interceptors.request.use(
    async (config) => {
      const token = store.state.auth.token;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Response interceptor
  api.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      // Pokud je chyba 401 (Unauthorized) a není to opakovaný požadavek
      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        try {
          // Pokus o obnovení autentizace
          const isAuthenticated = await store.dispatch('auth/checkAuth');
          
          if (isAuthenticated) {
            // Pokud se podařilo obnovit autentizaci, opakujeme původní požadavek
            const token = store.state.auth.token;
            originalRequest.headers.Authorization = `Bearer ${token}`;
            return api(originalRequest);
          } else {
            // Pokud se nepodařilo obnovit autentizaci, přesměrujeme na login
            await store.dispatch('auth/logout');
            router.push('/login');
            return Promise.reject(new Error('Relace vypršela, prosím přihlaste se znovu'));
          }
        } catch (refreshError) {
          await store.dispatch('auth/logout');
          router.push('/login');
          return Promise.reject(refreshError);
        }
      }

      // Upravené chybové zprávy pro lepší UX
      if (error.response?.status === 403) {
        return Promise.reject(new Error('Nemáte oprávnění k této operaci'));
      }

      // Vytvoření srozumitelné chybové zprávy
      const errorMessage = error.response?.data?.message || error.message;
      return Promise.reject(new Error(errorMessage));
    }
  );
};

const apiMethods = {
  // SKz metody
  async createSKzItem(data) {
    try {
      const response = await api.post('/skz', data);
      return response;
    } catch (error) {
      console.error('Error in createSKzItem:', error);
      throw new Error(`Chyba při vytváření položky: ${error.message}`);
    }
  },



  async updateSKzItem(id, data) {
    try {
      console.log('Updating SKz item:', { id, data });
      
      // Vyčistíme data před odesláním
      const cleanedData = Object.fromEntries(
        Object.entries(data)
          .filter(([key, value]) => 
            !key.endsWith('_text') && 
            value !== undefined && 
            value !== null && 
            value !== ''
          )
      );

      console.log('Cleaned data for update:', cleanedData);
      const response = await api.put(`/skz/${id}`, cleanedData);
      
      if (!response.data.success) {
        throw new Error(response.data.message || 'Neznámá chyba při aktualizaci');
      }

      return response;
    } catch (error) {
      console.error('Error in updateSKzItem:', error);
      if (error.response?.status === 404) {
        throw new Error('Item not found!');
      }
      throw new Error(error.response?.data?.message || error.message);
    }
  },


  async deleteSKzItem(id) {
    try {
      const response = await api.delete(`/skz/${id}`);
      return response;
    } catch (error) {
      console.error('Error in deleteSKzItem:', error);
      throw new Error(`Failed delete item: ${error.message}`);
    }
  },

  async getSKzItems(page = 1, limit = 10, search = '', filters = [], language = 'CZ') {
    try {
      const params = { page, limit, search, language };
      if (filters.length > 0) {
        params.filters = JSON.stringify(filters);
      }
      const response = await api.get('/skz', { params });
      return response;
    } catch (error) {
      console.error('Error in getSKzItems:', error);
      throw new Error(`Failed to load item: ${error.message}`);
    }
  },

  async getModalFields() {
    try {
      const response = await api.get('/skz/modal-fields');
      return response.data;
    } catch (error) {
      console.error('Error in getModalFields:', error);
      throw new Error(`Chyba při načítání polí formuláře: ${error.message}`);
    }
  },

// services/api.js
async fetchDropdownOptions(field) {
  if (!field) {
    console.error('No field name provided for dropdown options');
    return [];
  }

  try {
    console.log(`Fetching dropdown options for ${field}`);
    const response = await api.get(`/skz/dropdown-options/${field}`);
    
    if (!response.data || !Array.isArray(response.data)) {
      console.error(`Invalid response format for ${field}:`, response.data);
      return [];
    }

    const formattedOptions = response.data.map(item => ({
      value: item.value || item.ID || item.VPrID,
      text: item.text || item.VPrNameEN || item.IDS || item.VPrNameCZ || item.VPrNameDE,
      orderIndex: item.orderIndex || item.OrderFld,
      ID: item.ID || item.value || item.VPrID,
      IDS: item.IDS || item.text || item.VPrNameEN
    })).filter(option => option.value && option.text);

    console.log(`Formatted ${formattedOptions.length} options for ${field}:`, formattedOptions);
    return formattedOptions;
  } catch (error) {
    console.error(`Error fetching options for ${field}:`, error);
    return [];
  }
},



  // Autentizační metody
  async login(credentials) {
    try {
      const response = await api.post('/auth/login', credentials);
      return response;
    } catch (error) {
      console.error('Error in login:', error);
      throw error;
    }
  },

  async verifyToken() {
    try {
      const response = await api.get('/auth/verify-token');
      return response;
    } catch (error) {
      console.error('Error in verifyToken:', error);
      throw error;
    }
  }
};

// Kombinujeme API instanci, metody a setupInterceptors do jednoho exportu
export default {
  ...api,
  ...apiMethods,
  setupInterceptors
};