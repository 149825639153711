<!-- src/views/Home.vue -->
<template>
  <div class="home">
    
    
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'HomeView'
}
</script>