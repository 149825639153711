// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import UserLogin from '../views/UserLogin.vue';
import Home from '../views/Home.vue';
import store from '@/store';

const routes = [
  { 
    path: '/login', 
    name: 'Login', 
    component: UserLogin,
    meta: { requiresAuth: false }
  },
  { 
    path: '/', 
    component: Home,
    meta: { requiresAuth: true },
    children: [
      { path: '', redirect: { name: 'Dashboard' } },
      { 
        path: 'dashboard', 
        name: 'Dashboard', 
        component: () => import('../views/Dashboard.vue'),
        meta: { requiresAuth: true }
      },
      { 
        path: 'skz', 
        name: 'SKz', 
        component: () => import('../views/SKz.vue'),
        meta: { requiresAuth: true }
      },
      { 
        path: 'obj', 
        name: 'Obj', 
        component: () => import(/* webpackChunkName: "obj" */ '../views/Obj.vue'),
        meta: { requiresAuth: true }
      },
      { 
        path: 'doprava', 
        name: 'Doprava', 
        component: () => import(/* webpackChunkName: "doprava" */ '../views/Doprava.vue'),
        meta: { requiresAuth: true }
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async (to, from, next) => {
  console.log('Route guard - checking auth for:', to.path);
  
  // Kontrola, zda je uživatel přihlášen
  const isAuthenticated = await store.dispatch('auth/checkAuth');
  console.log('Auth check result:', isAuthenticated);

  // Pokud stránka vyžaduje přihlášení a uživatel není přihlášen
  if (to.meta.requiresAuth && !isAuthenticated) {
    console.log('Auth required but not logged in, redirecting to login');
    next('/login');
    return;
  }

  // Pokud je uživatel přihlášen a snaží se dostat na login stránku
  if (to.path === '/login' && isAuthenticated) {
    console.log('Already logged in, redirecting to dashboard');
    next('/dashboard');
    return;
  }

  // V ostatních případech povolit navigaci
  next();
});

export default router;