// composables/useTranslations.js
import { computed } from 'vue';
import { useStore } from 'vuex';

export function useTranslations() {
  const store = useStore();
  
  const translate = (key) => {
    return store.getters['translations/translate'](key);
  };

  return {
    translate,
    currentLanguage: computed(() => store.state.translations.currentLanguage),
    isLoading: computed(() => store.state.translations.loading),
    error: computed(() => store.state.translations.error)
  };
}