<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" app v-if="isLoggedIn">
      <v-list>
        <v-list-item to="/dashboard" link>
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ translate('Dashboard') }}</v-list-item-title>
        </v-list-item>

        <v-list-item to="/skz" link>
          <v-list-item-icon>
            <v-icon>mdi-format-list-bulleted</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ translate('Warehouse Tobwell') }}</v-list-item-title>
        </v-list-item>

        
        <v-list-item to="/doprava" link>
          <template v-slot:prepend>
            <v-icon>mdi-truck</v-icon>
          </template>
          
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar color="primary" v-if="isLoggedIn">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>{{ translate('Warehouse Tobwell') }}</v-toolbar-title>
      <v-spacer></v-spacer>

      <!-- Přidaný Language Selector -->
      <language-selector class="mr-4" />

      <v-btn @click="handleLogout" variant="text">
        {{ translate('@Logout') }}
        <v-icon right>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useTranslations } from './composables/useTranslations';
import LanguageSelector from '@/components/LanguageSelector.vue';

export default {
  name: 'App',
  components: {
    LanguageSelector
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const drawer = ref(true);
    const { translate } = useTranslations();
    const translatedTitle = computed(() => translate('@DashboardSKz'));

    const isLoggedIn = computed(() => store.getters['auth/isAuthenticated']);

    onMounted(async () => {
      console.log('App mounted - initializing auth and translations');
      await store.dispatch('auth/initializeAuth');
      const isAuthenticated = await store.dispatch('auth/checkAuth');

      if (isAuthenticated) {
        // Načteme překlady pro aktuální jazyk
        const currentLanguage = store.state.translations.currentLanguage;
        console.log('Loading translations for language:', currentLanguage);
        await store.dispatch('translations/changeLanguage', currentLanguage);
      }

      if (!isAuthenticated && router.currentRoute.value.path !== '/login') {
        await router.push('/login');
      }
    });

    const handleLogout = async () => {
      console.log('Logging out');
      try {
        await store.dispatch('auth/logout');
        await router.push('/login');
      } catch (error) {
        console.error('Logout error:', error);
      }
    };

    return {
      drawer,
      isLoggedIn,
      handleLogout,
      translate
    };
  }
};
</script>

<style>
.v-navigation-drawer {
  width: 250px !important;
}

.v-list-item {
  margin-bottom: 4px;
}

.v-list-item--active {
  background-color: rgb(var(--v-theme-primary)) !important;
  color: white !important;
}

.v-list-item--active .v-icon {
  color: white !important;
}
</style>