// store/modules/translations.js
export const translations = {
  namespaced: true,

  state: () => ({
    translations: {},
    loading: false,
    error: null
  }),

  getters: {
    translate: (state) => (key) => {
      if (!key) return '';
      const cleanKey = key.startsWith('@') ? key.substring(1) : key;
      return state.translations[cleanKey] || key;
    }
  },

  mutations: {
    SET_TRANSLATIONS(state, translations) {
      state.translations = translations;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_ERROR(state, error) {
      state.error = error;
    }
  },

  actions: {
    async fetchTranslations({ commit }) {
      commit('SET_LOADING', true);
      try {
        const [appResponse, columnResponse] = await Promise.all([
          api.get('/skz/translations/app'),
          api.get('/skz/translations/columns')
        ]);
        
        const translations = {
          ...appResponse.data,
          ...columnResponse.data
        };
        
        commit('SET_TRANSLATIONS', translations);
      } catch (error) {
        console.error('Error fetching translations:', error);
        commit('SET_ERROR', error);
      } finally {
        commit('SET_LOADING', false);
      }
    }
  }
};