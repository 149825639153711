// store/modules/auth.js
import api from '@/services/api' // Opravená cesta k API modulu

const TOKEN_KEY = 'user_token'
const USER_KEY = 'user_data'

const state = {
  token: localStorage.getItem(TOKEN_KEY) || null,
  user: JSON.parse(localStorage.getItem(USER_KEY) || 'null'),
  isLoggedIn: !!localStorage.getItem(TOKEN_KEY)
}

const getters = {
  isAuthenticated: state => !!state.token,
  currentUser: state => state.user,
  isLoggedIn: state => state.isLoggedIn
}

const mutations = {
  SET_TOKEN(state, token) {
    state.token = token
    state.isLoggedIn = !!token
    if (token) {
      localStorage.setItem(TOKEN_KEY, token)
    } else {
      localStorage.removeItem(TOKEN_KEY)
    }
  },
  SET_USER(state, user) {
    state.user = user
    if (user) {
      localStorage.setItem(USER_KEY, JSON.stringify(user))
    } else {
      localStorage.removeItem(USER_KEY)
    }
  },
  CLEAR_AUTH(state) {
    state.token = null
    state.user = null
    state.isLoggedIn = false
    localStorage.removeItem(TOKEN_KEY)
    localStorage.removeItem(USER_KEY)
  }
}

const actions = {
  async login({ commit }, credentials) {
    try {
      const response = await api.login(credentials)
      if (response.data.success) {
        const { token, user } = response.data
        commit('SET_TOKEN', token)
        commit('SET_USER', user)
        return true
      }
      return false
    } catch (error) {
      console.error('Login error:', error)
      commit('CLEAR_AUTH')
      throw error
    }
  },

  async logout({ commit }) {
    try {
      commit('CLEAR_AUTH')
      return true
    } catch (error) {
      console.error('Logout error:', error)
      throw error
    }
  },

  async checkAuth({ commit, state }) {
    try {
      if (!state.token) {
        commit('CLEAR_AUTH')
        return false
      }

      const response = await api.verifyToken() // Upraveno na použití api metody
      if (response.data.valid) {
        if (response.data.user) {
          commit('SET_USER', response.data.user)
        }
        return true
      } else {
        commit('CLEAR_AUTH')
        return false
      }
    } catch (error) {
      console.error('Auth check error:', error)
      commit('CLEAR_AUTH')
      return false
    }
  },

  initializeAuth({ commit }) {
    const token = localStorage.getItem(TOKEN_KEY)
    const user = JSON.parse(localStorage.getItem(USER_KEY) || 'null')
    
    if (token) {
      commit('SET_TOKEN', token)
    }
    if (user) {
      commit('SET_USER', user)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}