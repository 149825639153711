<template>
  <v-menu location="bottom">
    <template v-slot:activator="{ props }">
      <v-btn
        v-bind="props"
        color="white"
        variant="text"
        class="language-selector"
        density="comfortable"
        :loading="isLoading"
      >
        {{ getCurrentLanguageLabel }}
        <v-icon right>mdi-chevron-down</v-icon>
      </v-btn>
    </template>
    <v-list density="comfortable">
      <v-list-item
        v-for="lang in languages"
        :key="lang.code"
        :value="lang.code"
        :active="lang.code === currentLanguage"
        @click="handleLanguageChange(lang.code)"
      >
        <template v-slot:prepend v-if="lang.code === currentLanguage">
          <v-icon color="primary" size="small">mdi-check</v-icon>
        </template>
        <v-list-item-title>{{ lang.name }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useTranslations } from '@/composables/useTranslations';

export default {
  name: 'LanguageSelector',
  setup() {
    const store = useStore();
    const { translate } = useTranslations();

    const languages = [
      { code: 'CZ', name: 'Čeština' },
      { code: 'EN', name: 'English' },
      { code: 'DE', name: 'Deutsch' }
    ];

    const currentLanguage = computed(() => store.state.translations.currentLanguage);

    const isLoading = computed(() => store.state.translations.loading);

    const getCurrentLanguageLabel = computed(() => {
      const lang = languages.find(l => l.code === currentLanguage.value);
      return lang ? lang.name : 'Čeština';
    });

    const handleLanguageChange = async (code) => {
      console.log('Language change requested:', code);
      try {
        await store.dispatch('translations/changeLanguage', code);
        console.log('Language changed successfully');
      } catch (error) {
        console.error('Failed to change language:', error);
      }
    };

    onMounted(() => {
      console.log('LanguageSelector mounted');
    });

    return {
      languages,
      currentLanguage,
      isLoading,
      getCurrentLanguageLabel,
      handleLanguageChange,
      translate
    };
  }
};
</script>

<style scoped>
.language-selector {
  text-transform: none !important;
  letter-spacing: normal !important;
  min-width: 120px !important;
}

:deep(.v-list-item--active) {
  background-color: rgba(var(--v-theme-primary), 0.1);
}

:deep(.v-list-item) {
  min-height: 40px;
}

:deep(.v-list) {
  padding: 4px;
}
</style>