import { createStore } from 'vuex';
import { translations } from './modules/translations';
import auth from './modules/auth';

// Create the store
const store = createStore({
 strict: process.env.NODE_ENV !== 'production',
 
 modules: {
   auth,
   translations
   
 },
 
 state: {
   // Global state if needed
 },
 

 mutations: {
   // Global mutations if needed
 },
 
 actions: {
  async initializeStore({ dispatch }) {
    await registerModules();
  }
},
});

// Define the register modules function
const registerModules = async () => {
 // Dynamicky načítané moduly podle cesty
 if (window.location.pathname.includes('/dashboard')) {
   const dashboard = await import(/* webpackChunkName: "store-dashboard" */ './modules/dashboard');
   if (!store.hasModule('dashboard')) {
     store.registerModule('dashboard', dashboard.default);
   }
 }

 if (window.location.pathname.includes('/skz')) {
   const skz = await import(/* webpackChunkName: "store-skz" */ './modules/skz');
   if (!store.hasModule('skz')) {
     store.registerModule('skz', skz.default);
   }
 }
};

// Initialize modules
registerModules();

// Hot module replacement for development
if (module.hot) {
 module.hot.accept(['./modules/auth', './modules/dashboard', './modules/skz'], () => {
   const auth = require('./modules/auth').default;
   const dashboard = require('./modules/dashboard').default;
   const skz = require('./modules/skz').default;
   
   store.hotUpdate({
     modules: {
       auth,
       dashboard,
       skz,
     },
   });
 });
}

export default store;