<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  setup() {
    const store = useStore()
    const router = useRouter()
    const username = ref('')
    const password = ref('')
    const loading = ref(false)
    const error = ref('')

    const handleLogin = async () => {
  loading.value = true
  error.value = ''
  
  try {
    console.log('Login attempt');
    const success = await store.dispatch('auth/login', {
      username: username.value,
      password: password.value
    })

    console.log('Login result:', success);

    if (success) {
      console.log('Login successful - redirecting');
      await router.push('/warehouse')
    } else {
      error.value = 'Přihlášení selhalo'
    }
  } catch (err) {
    console.error('Login error:', err)
    error.value = err.message || 'Přihlášení selhalo'
  } finally {
    loading.value = false
  }
}

    return {
      username,
      password,
      loading,
      error,
      handleLogin
    }
  }
}
</script>

<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12" sm="8" md="6" lg="4">
        <v-card class="elevation-12">
          <v-card-title>Přihlášení</v-card-title>
          <v-card-text>
            <v-form @submit.prevent="handleLogin">
              <v-text-field
                v-model="username"
                label="Uživatelské jméno"
                required
                :disabled="loading"
              ></v-text-field>
              
              <v-text-field
                v-model="password"
                label="Heslo"
                type="password"
                required
                :disabled="loading"
              ></v-text-field>

              <v-alert
                v-if="error"
                type="error"
                dismissible
              >
                {{ error }}
              </v-alert>

              <v-btn
                color="primary"
                type="submit"
                block
                :loading="loading"
              >
                Přihlásit
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>